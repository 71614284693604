import { useMutation } from "@apollo/client"
import { UPDATE_ORDER } from "../graphql/mutations/updateOrder"

export const useUpdateOrder = () => {
    const [updateOrder] = useMutation(UPDATE_ORDER,{
        onError:(e) => {
            console.log(e)
        }
    })
    return {
        updateOrder
    }
}