import { gql } from "@apollo/client"

export const UPDATE_CUSTOMER_ADDRESS = gql`
mutation updateCustomer($input:  UpdateCustomerInput!) {
    updateCustomer(input:  $input) {
        customer {
          shipping {
            postcode
            state
          }
        }
      }
}
`