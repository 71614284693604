/* eslint-disable no-undef */
import React, { useEffect, useState, useCallback, useContext, useLayoutEffect } from "react"

import BillingDetails from "./BillingDetails"
import YourOrder from "./YourOrder"
import Layout from "../../components/layout"
import Message from "../../components/Message"
import Payment from "./Payment"
import "./checkout.scss"
import { useMutation } from "@apollo/client"
import {
  POST_FIRSTDATA_TRANSACTION,
  POST_FATZEBRA_TRANSACTION
} from "../../graphql/mutations/checkout/payment"
import { UPDATE_CUSTOMER_ADDRESS } from "../../graphql/mutations/cart/updateCustomerAddress"
import { randomString } from "../../helpers/randomString"
import { isUserLoggedIn } from "../../utils/function"
import { useCartContext } from "../../views/cart/CartContext"
import Validate from "../../utils/validate/validate"
import { v4 } from "uuid"
import { navigate } from "gatsby"
import { UPDATE_DATA_USER } from "../../views/cart/CartContext"
import CHECKOUT_MUTATION from "../../graphql/mutations/checkout/checkout"
import { debounce } from "lodash"
import { useMemo } from "react"
import aes from "crypto-js/aes"
import encHex from "crypto-js/enc-hex"
import padZeroPadding from "crypto-js/pad-zeropadding"
import { useGetCustomer } from "../../hooks/useGetCustomer"
import { getDefaultField, FbTracking } from "../../utils/function"
import Seo from "../../components/seo"
import { useUpdateOrder } from "../../hooks/useUpdateOrder"
import { toast } from "react-toastify"
//       number: "5424180279791732",
//       number: "5426064000420979",
//       securityCode: "977",
//       expiryDate: 12/24
//       card_number: "4111111111111111",
//       card_holder: "TEST",
//       card_expiry: "01/2024",
//       cvv: "123"
function Checkout() {
  const { customer } = useGetCustomer()
  const { billingInfo, deliveryInfo } = getDefaultField()
  const [form_bl, setForm_bl] = useState(
    billingInfo
      ? billingInfo
      : {
          first_name: "",
          last_name: "",
          company: "",
          house_number: "",
          apartment: "",
          suburb: "",
          state: "",
          postcode: "",
          phone: "",
          email: ""
        }
  )
  const [form_deliver, setForm_deliver] = useState(
    deliveryInfo
      ? deliveryInfo
      : {
          first_name: "",
          last_name: "",
          company: "",
          house_number: "",
          apartment: "",
          suburb: "",
          state: "",
          postcode: "",
          order_note: ""
        }
  )
  const [paymentForm, setPaymentForm] = useState({
    number: "",
    securityCode: "",
    time: ""
  })
  const auth = isUserLoggedIn()
  const [viewOtherAddress, setViewOtherAddress] = useState(false)
  const [shippingMethodLoading, setShippingMethodLoading] = useState(false)
  const [checkoutSuccess, setCheckoutSuccess] = useState(false)
  const [billingErrorMessage, setBillingErrorMessage] = useState()
  const [deliveryErrorMessage, setDeliveryErrorMessage] = useState()
  const [isUpdateOrder, setIsUpdateOrder] = useState(false)
  const [loadingCheckout, setLoadingCheckout] = useState(false)
  const [backspaceFlag, setBackspaceFlag] = useState(false)
  const [shippingCost, setShippingCost] = useState(0)
  const [shippingName, setShippingName] = useState("")
  const { updateOrder } = useUpdateOrder()

  const {
    cartDetail,
    refetch,
    dispatch,
    loading,
    setCartItems,
    clearCart,
    state,
    valuePayment,
    setValuePayment,
    shippingChosen,
    setShippingChosen
  } = useCartContext()
  const seo = {
    title: "Checkout - Backa Australia",
    description: ""
  }
  const [updateCustomerAddress] = useMutation(UPDATE_CUSTOMER_ADDRESS, {
    onCompleted: data => {
      refetch()
    },
    onError: error => console.log(error),
    fetchPolicy: "no-cache"
  })
  const [handlePayment, { loading: loadingPayment }] = useMutation(
    valuePayment === "fatzebra" ? POST_FATZEBRA_TRANSACTION : POST_FIRSTDATA_TRANSACTION,
    {
      onCompleted: data => {
        setLoadingCheckout(false)
        if (valuePayment === "fatzebra") {
          const formatData = JSON.parse(data.postFatzebraTransaction.result)
          const responseFormat = formatData?.response
          if (responseFormat?.successful === false || formatData?.successful === false) {
            if (formatData?.errors === null || formatData?.errors === "null") {
              toast.error("Please check your card information again!")
            } else {
              toast.error(`${formatData?.errors}`)
            }
          } else {
            setIsUpdateOrder(true)
            clearCart()
            setCartItems([])
            navigate("/order-success")
          }
        } else {
          setIsUpdateOrder(true)
          clearCart()
          setCartItems([])
          navigate("/order-success")
        }
      },
      onError: err => {
        setLoadingCheckout(false)
        toast.error(
          err ? <div dangerouslySetInnerHTML={{ __html: err.message }} /> : "Payment error"
        )
      }
    }
  )
  const [addInfoCheckout] = useMutation(CHECKOUT_MUTATION, {
    onCompleted: data => {
      dispatch({ type: UPDATE_DATA_USER, payload: data.checkout })
      setCheckoutSuccess(true)
      if (valuePayment === "cod") {
        setCartItems([])
        clearCart()
        setLoadingCheckout(false)
        navigate("/order-success")
      }
    },
    onError: err => {
      setLoadingCheckout(false)
      toast.error(
        err ? <div dangerouslySetInnerHTML={{ __html: err.message }} /> : "Checkout error"
      )
    }
  })
  const checkOut = () => {
    setLoadingCheckout(true)
    addInfoCheckout({
      variables: {
        input: {
          clientMutationId: randomString(),
          account: {
            username: auth ? auth.user : "",
            password: ""
          },
          shipping: {
            firstName: form_deliver.first_name ? form_deliver.first_name : form_bl.first_name,
            lastName: form_deliver.last_name ? form_deliver.last_name : form_bl.last_name,
            address1: form_deliver.house_number ? form_deliver.house_number : form_bl.house_number,
            address2: form_deliver.apartment ? form_deliver.apartment : form_bl.apartment,
            city: form_deliver.suburb ? form_deliver.suburb : form_bl.suburb,
            email: form_deliver.email ? form_deliver.email : form_bl.email,
            country: "AU",
            state: form_deliver.state ? form_deliver.state : form_bl.state,
            postcode: form_deliver.postcode ? form_deliver.postcode : form_bl.postcode,
            phone: form_bl.phone
          },
          billing: {
            firstName: form_bl.first_name,
            lastName: form_bl.last_name,
            address1: form_bl.house_number,
            address2: form_bl.apartment,
            city: form_bl.suburb,
            country: "AU",
            state: form_bl.state,
            postcode: form_bl.postcode,
            email: form_bl.email,
            phone: form_bl.phone,
            company: form_bl.company
          },
          shipToDifferentAddress: true,
          paymentMethod: valuePayment,
          isPaid: false,
          transactionId: randomString(),
          customerNote: `${form_deliver.order_note} - Shipping method ${shippingName}: $${shippingCost}`,
          shippingMethod: [shippingChosen]
        }
      }
    })
  }
  const handlePaymentChange = e => {
    if (e.target.name === "time") {
      let textTemp = e.target.value
      if (textTemp[0] !== "1" && textTemp[0] !== "0") {
        textTemp = ""
      }
      if (textTemp.length === 2) {
        if (parseInt(textTemp.substring(0, 2)) > 12 || parseInt(textTemp.substring(0, 2)) == 0) {
          textTemp = textTemp[0]
        } else if (textTemp.length === 2 && !backspaceFlag) {
          textTemp += "/"
          setBackspaceFlag(true)
        } else if (textTemp.length === 2 && backspaceFlag) {
          textTemp = textTemp[0]
          setBackspaceFlag(false)
        } else {
          textTemp = textTemp[0]
        }
      }
      setPaymentForm({ ...paymentForm, [e.target.name]: textTemp })
    } else {
      setPaymentForm({ ...paymentForm, [e.target.name]: e.target.value })
    }
  }
  const payment = () => {
    let key = encHex.parse("9788342a77b5683d4d29b64a39f6b2e7")
    let iv = encHex.parse("4d0cab3e3d39aa675509f862aace7a49")
    const fatZebraCardInfor = {
      data: {
        card_number: paymentForm.number,
        card_holder: paymentForm.holder,
        card_expiry: paymentForm.time.replace(
          paymentForm.time.slice(-2),
          `20${paymentForm.time.slice(-2)}`
        ),
        cvv: paymentForm.securityCode
      }
    }
    let encrypted = aes
      .encrypt(JSON.stringify(fatZebraCardInfor), key, { iv: iv, padding: padZeroPadding })
      .toString()
    handlePayment({
      variables: {
        input:
          valuePayment === "fatzebra"
            ? {
                amount: cartDetail
                  ? parseInt(Number(cartDetail?.total?.replace("$", "")).toFixed(2) * 100)
                  : 0,
                card_details: encrypted
              }
            : {
                clientMutationId: v4(),
                postData: JSON.stringify({
                  requestType: "PaymentCardSaleTransaction",
                  storeId: "3650645084",
                  transactionAmount: {
                    total: cartDetail ? cartDetail?.total?.replace("$", "") : "0",
                    currency: "AUD"
                  },
                  paymentMethod: {
                    paymentCard: {
                      number: paymentForm.number,
                      securityCode: paymentForm.securityCode,
                      expiryDate: {
                        month: paymentForm.time.slice(0, 2),
                        year: paymentForm.time.slice(-2)
                      }
                    }
                  }
                })
              }
      }
    })
  }

  const handleCheckOut = () => {
    setBillingErrorMessage({})
    setDeliveryErrorMessage({})
    const error_form_bl = Validate(form_bl)
    const error_form_deliver = Validate(form_deliver)
    if (!viewOtherAddress) {
      if (Object.keys(error_form_bl).length === 0) {
        checkOut()
      } else {
        setBillingErrorMessage(error_form_bl)
        toast.error("Required field still empty")
      }
    } else {
      if (Object.keys(error_form_bl).length === 0 && Object.keys(error_form_deliver).length === 0) {
        checkOut()
      } else {
        setBillingErrorMessage(error_form_bl)
        setDeliveryErrorMessage(error_form_deliver)
        toast.error("Required field still empty")
      }
    }
  }

  const debounceInput = useMemo(
    () =>
      debounce(
        (name, nextValue) =>
          updateCustomerAddress({
            variables: {
              input: {
                shipping: {
                  country: "AU",
                  [name]: nextValue
                }
              }
            }
          }),
        1500
      ),
    []
  )
  const handleBillingChange = event => {
    const newState = { ...form_bl, [event.target.name]: event.target.value }
    setForm_bl(newState)
    sessionStorage.setItem("billing", JSON.stringify(newState))
    if (event.target.name === "state" || event.target.name === "postcode") {
      setShippingMethodLoading(true)
      debounceInput(event.target.name, event.target.value)
    }
  }
  const handleDeliverChange = event => {
    const newState1 = { ...form_deliver, [event.target.name]: event.target.value }
    setForm_deliver(newState1)
    sessionStorage.setItem("delivery", JSON.stringify(newState1))
  }
  useEffect(() => {
    if (!loading) {
      setShippingMethodLoading(false)
      if (
        cartDetail &&
        cartDetail.contents &&
        cartDetail.contents.nodes &&
        cartDetail.contents.nodes.length <= 0
      ) {
        navigate("/cart")
      }
    }
  }, [loading, cartDetail])

  useEffect(() => {
    if (checkoutSuccess) {
      FbTracking(
        "Checkout",
        state.dataUser && state.dataUser.order && state.dataUser.order.total
          ? (Number(state.dataUser.order.total?.replace("$", "")) + shippingCost).toFixed(2)
          : 0
      )
      if (valuePayment !== "cod") {
        payment()
      }
    }
  }, [checkoutSuccess])
  useEffect(() => {
    if (isUpdateOrder) {
      updateOrder({
        variables: {
          input: {
            order_id:
              state.dataUser && state.dataUser.order && state.dataUser.order.databaseId
                ? state.dataUser.order.databaseId
                : ""
          }
        }
      })
      FbTracking(
        "Payment",
        state.dataUser && state.dataUser.order && state.dataUser.order.total
          ? (Number(state.dataUser.order.total?.replace("$", "")) + shippingCost).toFixed(2)
          : 0
      )
    }
  }, [isUpdateOrder])
  useEffect(() => {
    if (customer) {
      const { billing, shipping } = customer
      if (billing) {
        setForm_bl(
          billingInfo
            ? billingInfo
            : {
                first_name: billing.firstName ? billing.firstName : "",
                last_name: billing.lastName ? billing.lastName : "",
                company: billing.company ? billing.company : "",
                house_number: billing.address1 ? billing.address1 : "",
                apartment: billing.address2 ? billing.address2 : "",
                suburb: billing.city ? billing.city : "",
                state: billing.state ? billing.state : "",
                postcode: billing.postcode ? billing.postcode : "",
                phone: billing.phone ? billing.phone : "",
                email: billing.email ? billing.email : ""
              }
        )
        updateCustomerAddress({
          variables: {
            input: {
              shipping: {
                country: "AU",
                postcode: billing.postcode,
                state: billing.state
              }
            }
          }
        })
      }
      if (shipping) {
        setForm_deliver({
          first_name: shipping.firstName ? shipping.firstName : "",
          last_name: shipping.lastName ? shipping.lastName : "",
          company: shipping.company ? shipping.company : "",
          house_number: shipping.address1 ? shipping.address1 : "",
          apartment: shipping.address2 ? shipping.address2 : "",
          suburb: shipping.city ? shipping.city : "",
          state: shipping.state ? shipping.state : "",
          postcode: shipping.postcode ? shipping.postcode : "",
          order_note: ""
        })
      }
    }
  }, [customer])
  return (
    <Layout pageClass="checkout-page">
      <Seo title={seo.title} description={seo.description} />
      <div className="checkout-page-wrap">
        <form>
          <div className="container-wrap">
            <div className="container">
              <Message cartDetail={cartDetail} refetch={refetch} />
            </div>
            <div className="container">
              <BillingDetails
                form_bl={form_bl}
                form_deliver={form_deliver}
                handleBillingChange={handleBillingChange}
                handleDeliverChange={handleDeliverChange}
                viewOtherAddress={viewOtherAddress}
                setViewOtherAddress={setViewOtherAddress}
                error_form_bl={billingErrorMessage}
                error_form_deliver={deliveryErrorMessage}
              />
            </div>
            <div className="container">
              <YourOrder
                cartLoading={loading}
                refetch={refetch}
                setValueCheckBox={setShippingChosen}
                valuePayment={valuePayment}
                valueCheckBox={shippingChosen}
                setValuePayment={setValuePayment}
                cartDetail={cartDetail}
                shippingMethodLoading={shippingMethodLoading}
                setShippingCost={setShippingCost}
                setShippingName={setShippingName}
              />
            </div>
            <div className="container">
              <Payment
                handleCheckOut={handleCheckOut}
                handleChange={handlePaymentChange}
                paymentForm={paymentForm}
                valuePayment={valuePayment}
                loadingPayment={loadingPayment}
                loadingCheckout={loadingCheckout}
              />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}
export default Checkout
