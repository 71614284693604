import { gql } from "@apollo/client";

export const GET_DATA_CUSTOMER = gql`
query NewQuery($customerId: Int) {
  customer(customerId: $customerId) {
    billing {
      address1
      address2
      city
      company
      country
      email
      firstName
      lastName
      phone
      postcode
      state
    }
    databaseId
    date
    email
    firstName
    id
    orderCount
    lastName
    shipping {
      address1
      address2
      city
      company
      country
      email
      firstName
      lastName
      phone
      postcode
      state
    }
    username
  }
}
`