import { gql } from "@apollo/client";
const CHECKOUT_MUTATION=gql`
mutation MyMutation($input: CheckoutInput!) {
    checkout(input: $input) {
      clientMutationId
      order {
        id
        orderKey
        customerNote
        date
        dateCompleted
        databaseId
        datePaid
        orderNumber
        lineItems {
          edges {
            node {
              product {
                node {
                  ... on SimpleProduct {
                    id
                    name
                  }
                }
              }
              quantity
              subtotal
              total
            }
          }
        }
        refunds {
          nodes {
            amount
          }
        }
        status
        subtotal
        total
      }
      redirect
      result
    }
  }
`;
export default CHECKOUT_MUTATION;