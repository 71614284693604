import { useMutation } from "@apollo/client"
import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import { toast } from "react-toastify"
import Input from "../../components/Input"
import { APPLY_COUPON } from "../../graphql/mutations/coupon/applyCoupon"
import { loginData } from "../../graphql/mutations/login/login"
import { randomString } from "../../helpers/randomString"
import { isUserLoggedIn, setAuth } from "../../utils/function"
import ButtonOutline from "../ButtonOutline"
import Loading from "../Loading"

import "./Message.scss"

const Message = ({ children, type = "success", cartDetail, refetch }) => {
  const coupon = Array.isArray(cartDetail?.appliedCoupons) && cartDetail?.appliedCoupons.length > 0
  const [username, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [couponField, setCouponField] = useState("")
  const [viewLogin, setViewLogin] = useState(false)
  const [viewCouponInput, setViewCouponInput] = useState(false)
  const [applyCoupon, { loading: loadingCoupon }] = useMutation(APPLY_COUPON)
  const authData = isUserLoggedIn()
  const classView = viewLogin ? "active" : ""
  const [submitLoginData, { data, loading, error }] = useMutation(loginData, {
    onCompleted: data => {
      const { login } = data
      if (login) {
        const authData = {
          authToken: login.authToken,
          user: login.user.username,
          userID: login.user.userId,
          customerID: login.customer.id
        }
        setAuth(authData)
        sessionStorage.setItem("isLogged", true)
        window.location.reload()
      }
    },
    onError: err => {
      toast.error("Your username or password incorrectly. Please re-enter")
    }
  })
  const handleApplyCoupon = e => {
    e.preventDefault()
    const tempCode = couponField.toLowerCase()
    applyCoupon({
      variables: {
        input: {
          code: tempCode
        }
      },
      onCompleted: () => {
        refetch()
      },
      onError: e => {
        toast.error(e)
      }
    })
  }
  const messLogin = authData ? null : (
    <div>
      <div className="messToggle">
        <p>
          Returning customer?
          <a onClick={() => setViewLogin(!viewLogin)} className="a-toggle">
            Click here to login
          </a>
        </p>
      </div>
      <div className={`view-login ${classView}`}>
        {loading && <Loading />}
        <div className="form-login">
          <p className="des-login">
            If you have shopped with us before, please enter your details below. If you are a new
            customer, please proceed to the Billing section.
          </p>
          <div className="row-login">
            <div className="col-login">
              <label htmlFor="username">Username or email </label>
              <span className="required">*</span>
              <Input
                type="text"
                className="input-text"
                name="username"
                id="username"
                autoComplete="username"
                onChange={e => setUserName(e.target.value)}
              />
            </div>
            <div className="col-login">
              <label htmlFor="password">Password </label>
              <span className="required">*</span>
              <Input
                type="password"
                className="input-text"
                name="password"
                id="password"
                onChange={e => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="action-login">
            <div className="check-wrap">
              <button
                className="login-btn"
                type="button"
                value="Login"
                onClick={e => {
                  e.preventDefault()
                  submitLoginData({
                    variables: {
                      input: {
                        username: username,
                        password: password,
                        clientMutationId: randomString()
                      }
                    }
                  })
                }}
              >
                Login
              </button>
            </div>
            <div>
              <input className="check-btn" type="checkbox" id="remember" />
              <label className="remember-label" htmlFor="remember">
                Remember me
              </label>
            </div>
            <div className="wrap-lostPassword">
              <a target="_blank" href="https://admin.backa.com.au/my-account/lost-password">
                Lost your password?
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const messCoupon = !coupon ? (
    <div>
      <div className="messToggle">
        <p>
          Have a coupon?
          <a to="#" className="a-toggle" onClick={() => setViewCouponInput(!viewCouponInput)}>
            Click here to enter your code
          </a>
        </p>
      </div>
      <div className={viewCouponInput ? "coupon-wrapper active" : "coupon-wrapper"}>
        {loadingCoupon && <Loading />}
        <div className="form-coupon">
          <div className="col-coupon">
            <label htmlFor="coupon">If you have a coupon code, please apply it below </label>
            <Input
              type="text"
              className="input-text"
              name="coupon"
              id="coupon"
              onChange={e => setCouponField(e.target.value)}
            />
          </div>
          <ButtonOutline
            type="button"
            disabled={!couponField || loadingCoupon ? true : false}
            className={!couponField || loadingCoupon ? "disabled" : ""}
            onClick={handleApplyCoupon}
          >
            Apply Coupon
          </ButtonOutline>
        </div>
      </div>
    </div>
  ) : null

  return (
    <div>
      <div className="c-message ">{children}</div>
      <div className="message green-message">{messLogin}</div>
      <div className="message green-message">{messCoupon}</div>
    </div>
  )
}

export default Message
