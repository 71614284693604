import { useQuery } from "@apollo/client"
import { GET_DATA_CUSTOMER } from '../graphql/queries/customer/getCustomer'
import { isUserLoggedIn } from "../utils/function"
export const useGetCustomer = () => {
    const authData = isUserLoggedIn()
    if (authData) {
        const { data } = useQuery(GET_DATA_CUSTOMER, {
            variables: {
                id: authData?.userID ? authData?.userID : ''
            }
        })
        return {
            customer: data?.customer,
        }
    } else {
        return {
            customer: null
        }
    }
}