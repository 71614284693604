import React, { useState, useEffect } from "react"
import FirstDataLogo from "./../../assets/images/firstdata.png"
import FatZebra from "../../assets/images/fatzebra-logo.svg"
import Loading from "../../components/Loading"
const Payment = ({
  handleCheckOut,
  handleChange,
  paymentForm,
  valuePayment,
  loadingCheckout,
  loadingPayment
}) => {
  return (
    <div className="checkout-payment-wrapper">
      {(loadingCheckout || loadingPayment) && <Loading />}
      {valuePayment !== "cod" ? (
        <div className="checkout-payment">
          <ul className="payment-methods">
            <li className="method-gateway">
              <input
                type="radio"
                id="method_firstdata_gateway"
                className="method_firstdata_gateway"
              />
              <label htmlFor="method_firstdata_gateway" className="gateway-logo-title">
                {valuePayment === "fatzebra" ? "Fat Zebra" : "First Data Gateway - Australia"}
                <img src={valuePayment === "fatzebra" ? FatZebra : FirstDataLogo} alt="firstdata" />
              </label>
              <div className="payment-box">
                <p className="first-intro">
                  {valuePayment === "fatzebra"
                    ? "Pay securely with Fat Zebra"
                    : "Pay securely with First Data Australia"}
                </p>
                <fieldset className="data-gateway-form">
                  <div className="form-row-payment payment-row">
                    <label htmlFor="gateway-card-number">
                      Card number
                      <span className="required"> *</span>
                    </label>
                    <input
                      className="input-full"
                      id="gateway-card-number"
                      name="number"
                      type="tel"
                      placeholder="•••• •••• •••• ••••"
                      inputMode="numeric"
                      onChange={handleChange}
                      // onBlur={handleValidate}
                    />
                  </div>
                  {
                    valuePayment === "fatzebra" && 
                    <div className="form-row-payment payment-row">
                      <label htmlFor="gateway-card-number">
                        Card holder
                        <span className="required"> *</span>
                      </label>
                      <input
                        className="input-full"
                        id="gateway-card-number"
                        name="holder"
                        placeholder="Card holder"
                        type="text"
                        onChange={handleChange}
                        // onBlur={handleValidate}
                      />
                    </div>
                  }
                  <div className="form-row-payment">
                    <div className="form-row-expire">
                      <label htmlFor="gateway-card-expire">
                        Expiry (MM/YY)
                        <span className="required"> *</span>
                      </label>
                      <input
                        className="input-full"
                        id="gateway-card-expire"
                        placeholder="MM / YY"
                        inputMode="numeric"
                        value={paymentForm?.time}
                        name="time"
                        onChange={handleChange}
                        // onBlur={handleValidate}
                        maxLength={5}
                      />
                    </div>
                    <div className="form-row-cvc">
                      <label htmlFor="gateway-card-cvc">
                        Card code
                        <span className="required"> *</span>
                      </label>

                      <input
                        className="input-full card-code"
                        id="gateway-card-cvc"
                        type="tel"
                        placeholder="CVC"
                        inputMode="numeric"
                        name="securityCode"
                        onChange={handleChange}
                        // onBlur={handleValidate}
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            </li>
          </ul>
          <div className="place-order">
            <div className="condition-wrapper">
              <div className="policy-text">
                <p>
                  Your personal data will be used to process your order, support your experience
                  throughout this website, and for other purposes described in our
                  <a
                    href="https://backa.com.au/privacy-policy/"
                    className="woocommerce-privacy-policy-link"
                    target="_blank"
                  >
                    {" "}
                    privacy policy
                  </a>
                  .
                </p>
              </div>
              <div className="order-btn-wrap">
                <button
                  type="button"
                  onClick={() => {
                    handleCheckOut()
                  }}
                  className={(loadingCheckout || loadingPayment) ? "order-btn disabled" : "order-btn"}
                  disabled={(loadingCheckout || loadingPayment) ? "true" : ""}
                >
                  Place Order{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="order-btn-wrap">
          <button
            type="button"
            onClick={() => {
              handleCheckOut()
            }}
            className={true ? "order-btn disabled" : "order-btn"}
            disabled={loadingCheckout ? true : false}
          >
            Place Order{" "}
          </button>
        </div>
      )}
    </div>
  )
}

export default Payment
