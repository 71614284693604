import React from "react"
import { gql } from "@apollo/client"

export const POST_FIRSTDATA_TRANSACTION = gql(`
mutation POST_FIRSTDATA_TRANSACTION($input: PostFirstdataTransactionInput!) {
    postFirstdataTransaction(input: $input) {
      postData
      result
    }
  }
`)
export const POST_FATZEBRA_TRANSACTION = gql(`
mutation POST_FATZEBRA_TRANSACTION($input: PostFatzebraTransactionInput!) {
  postFatzebraTransaction(input: $input) {
    result
  }
}
`)
