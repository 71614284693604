import validator from "validator"

const Validate = form => {
  let errors = {}
  for(let key in form) {
    if(key !== 'apartment' && key !== 'company') {
      if(validator.isEmpty(form[key])) {
        errors[key] ="This field is required"
      }
    }
  }
  return errors;
}
export default Validate
